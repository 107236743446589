<template>
  <v-sheet class="container">
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate :size="50" color="primary" />
    </div>
    <div v-else>
      <div v-if="worksheets && worksheets.length > 0 && selectedWorksheet">
        <v-alert
          outlined
          color="warning"
          class="mb-7 text-center"
          v-if="override"
          > Override Mode </v-alert
        >
        <div class="d-flex justify-space-between my-5 flex-column flex-md-row">
          <div class="d-flex justify-space-between">
            <v-menu
              v-if="worksheets && worksheets.length > 1"
              offset-y
              allow-overflow
              min-width="380px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">
                    mdi-file-document-multiple-outline
                  </v-icon>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.worksheet.xJobsxWorksheets",
                      jobsCount,
                      worksheets.length
                    )
                  }}
                </v-btn>
              </template>
              <v-list>
                <v-list-group
                  v-for="group in jobs"
                  :key="group.id"
                  @click.stop=""
                  :value="true"
                  no-action
                  sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ group.jobName }} ({{ group.status }} -
                        {{
                          group.worksheets.length
                        }}
                        Worksheets)</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    two-line
                    @click="changeSelectedWorksheet(worksheet)"
                    v-for="(worksheet, index) in group.worksheets"
                    :key="index"
                    class="pl-5"
                  >
                    <v-list-item-avatar>
                      <v-chip>{{ worksheet.entryCount }}</v-chip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="worksheet.processTypeName"
                          >{{ worksheet.processTypeName }} -</span
                        >
                        {{
                          worksheet.productName
                            ? `${worksheet.productName} (${worksheet.id})`
                            : `${worksheet.worksheetType} (${worksheet.id})`
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-caption"
                        >{{ worksheet.createTime | formatDateTime }} ({{
                          statusLang(worksheet.status)
                        }})</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-avatar
                      v-if="selectedWorksheet.id == worksheet.id"
                    >
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-menu>
            <div v-else class="mt-2 font-weight-light">
              {{ $vuetify.lang.t("$vuetify.worksheet.worksheet") }}
              {{ selectedWorksheet.id }}
            </div>
            <div>
              <v-btn :to="{ name: 'MyWorksheetHistory' }" class="ml-4" icon
                ><v-icon>mdi-history</v-icon></v-btn
              >
              <v-btn :to="{ name: 'home' }" class="ml-4" icon>
                <v-icon>mdi-home-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <div
            class="
              my-auto
              text-overline
              font-weight-bold
              mt-4 mt-md-0
              text-center text-md-right
            "
          >
            <span>
              <span
                class="mr-2"
                v-if="
                  selectedWorksheet.processDirection &&
                  selectedWorksheet.processDirection == 'Input'
                "
              >
                ({{ $vuetify.lang.t("$vuetify.worksheet.in") }})
              </span>
              <span
                class="mr-2"
                v-if="
                  selectedWorksheet.processDirection &&
                  selectedWorksheet.processDirection == 'Output'
                "
              >
                ({{ $vuetify.lang.t("$vuetify.worksheet.out") }})
              </span>
            </span>
            <span v-if="selectedWorksheet.processTypeName"
              >{{ selectedWorksheet.processTypeName }} -</span
            >
            <span v-if="selectedWorksheet.supplierName"
              >{{ selectedWorksheet.supplierName }} -</span
            >
            <span v-if="selectedWorksheet.customerName"
              >{{ selectedWorksheet.customerName }} -</span
            >
            {{
              selectedWorksheet.productName
                ? `${selectedWorksheet.productName} (${selectedWorksheet.id})`
                : `${selectedWorksheet.worksheetType} (${selectedWorksheet.id})`
            }}
            <div class="d-flex justify-center text-caption">
              <span v-if="selectedWorksheet.documentNumber">
                Doc #: {{ selectedWorksheet.documentNumber }}
              </span>
              <v-divider
                v-if="selectedWorksheet.documentNumber"
                vertical
                class="mx-2"
              />
              <span>{{ selectedWorksheet.createTime | formatDateTime }}</span>
            </div>
          </div>
        </div>

        <worksheet
          :key="selectedWorksheet.id"
          :worksheet.sync="selectedWorksheet"
          @deleted="removeWorksheet"
          @updated="updateWorksheetsStatus"
        />
      </div>
      <div class="text-center text-h6" v-else>
        {{ $vuetify.lang.t("$vuetify.worksheet.noActiveWorksheet") }}
        <div class="ma-8">
          <v-btn :to="{ name: 'MyWorksheetHistory' }" class="ml-4"
            ><v-icon class="mx-2">mdi-history</v-icon>
            {{ $vuetify.lang.t("$vuetify.worksheet.myHistory") }}</v-btn
          >
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import Worksheet from "@/components/processWorksheet/Worksheet.vue";
import { getProcessWorksheet } from "@/services/processWorksheet";

export default {
  components: { Worksheet },
  data: () => {
    return {
      worksheets: [],
      selectedWorksheet: null,
      loading: false,
      override: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["userRoles"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    jobs() {
      const jobs = this.worksheets.reduce((groups, w) => {
        const group = groups.find((g) => g.jobId == w.jobId);
        if (group) {
          const status =
            group.status == "Active" || !["SUBMITTED", "USED"].includes(w.status)
              ? "Active"
              : "Completed";

          group.worksheets.push(w);
          group.status = status;
        } else {
          const jobId = w.jobId;
          const jobName = w.jobId ? w.jobName : "Not Assigned";

          const status = !["SUBMITTED", "USED"].includes(w.status) ? "Active" : "Completed";
          groups.push({ jobId, jobName, worksheets: [w], status });
        }
        return groups;
      }, []);

      return jobs;
    },
    jobsCount(){
      const notAssigned = this.jobs.filter(j => !j.jobId);
    
      return this.jobs.length - notAssigned.length;
    }
  },
  methods: {
    async loadMyWorksheets() {
      return await this.$axios("/processWorksheet/my-worksheets")
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error.data.message);
          return [];
        });
    },
    removeWorksheet(worksheet) {
      const wsIndex = this.worksheets.findIndex((ws) => ws.id == worksheet.id);
      if (wsIndex > -1) {
        this.worksheets.splice(wsIndex, 1);
        this.selectedWorksheet = this.worksheets[0];
      }
    },
    changeSelectedWorksheet(worksheet) {
      // this.selectedWorksheet = worksheet;
      // this.$route.query.wsId = worksheet.id;
      this.$router.push({
        name: "MyWorksheets",
        params: { wsId: worksheet.id },
      });
    },
    statusLang(status) {
      let translation = status;
      switch (status) {
        case "SUBMITTED":
          translation = this.$vuetify.lang.t("$vuetify.worksheet.submitted");
          break;
        case "PENDING":
          translation = this.$vuetify.lang.t("$vuetify.worksheet.pending");
          break;
        case "USED":
          translation = this.$vuetify.lang.t("$vuetify.worksheet.used");
          break;
      }

      return translation;
    },
    async initMyWorksheet() {
      const worksheets = await this.loadMyWorksheets();

      //sort and default to latest worksheet as selected
      if (worksheets && worksheets.length > 0) {
        // this.worksheets = [...pendings, ...submitted];
        this.worksheets = worksheets.reverse();
        //Init selected worksheet if query param wsId is specified
        if (this.$route.params.wsId) {
          const matchWorksheet = this.worksheets.find(
            (ws) => ws.id == this.$route.params.wsId
          );
          if (matchWorksheet) {
            this.selectedWorksheet = matchWorksheet;
          } else {
            this.selectedWorksheet = this.worksheets[0];
          }
        } else {
          this.selectedWorksheet = this.worksheets[0];
        }

        const selectedWsId = this.selectedWorksheet
          ? this.selectedWorksheet.id
          : null;
        if (selectedWsId != this.$route.params.wsId) {
          this.$router.replace({
            name: "MyWorksheets",
            params: { wsId: selectedWsId },
          });
        }
      }
    },
    async initOverrideWorksheet() {
      //Check for permission
      const permissibleRoles = ["Administrator", "Manager", "Factory"];
      if (
        this.userRoles &&
        this.userRoles.some((r) => permissibleRoles.includes(r))
      ) {
        console.log("passed permission check...");
        const wsId = this.$route.params.wsId;
        if (wsId) {
          await getProcessWorksheet(wsId).then((resp) => {
            const worksheet = resp.data;
            if (worksheet) {
              this.selectedWorksheet = worksheet;
              this.worksheets = [this.selectedWorksheet];
              this.override = true;
            }
          });
        }
      }
    },
    updateWorksheetsStatus(){
      this.initMyWorksheet();
    }
  },
  async mounted() {
    this.loading = true;

    //Check for management override
    const override = this.$route.query.override;
    if (override) {
      console.log("managment override, checking for permission");
      this.initOverrideWorksheet();
    } else {
      await this.initMyWorksheet();
    }

    this.loading = false;
  },
};
</script>

<style scoped>
</style>
